<template>
  <v-card-text>
    <v-select
      v-model="location"
      :items="store.locations"
      item-text="name"
      return-object
      label="Select location"
      @change="() => {touched = true; (uses_shopify_labels = can_use_shopify_labels)}"
      />
    <!-- <v-checkbox
      label="Uses Shopify Labels (beta, US store & locations only)"
      v-model="uses_shopify_labels"
      :disabled="!can_use_shopify_labels"
      @change="touched=true"
      /> -->
      <v-select
        label="Which is your default label size?"
        v-model="label_size"
        :items="label_formats"
        item-text="description"
        item-value="title"
        v-if="uses_shopify_labels"
        @change="touched=true"
        />
    <!-- <v-alert
      type="info"
      round
      outlined
      v-if="uses_shopify_labels && !store.approved_shopify_beta"
      small>
      Shopify Labels is currently in beta mode. When you save, we will be notified and will reach out to Shopify to open up your store for buying Shopify Labels externally, which may take up to two working days. We will contact you via email once you're approved for beta testing.
    </v-alert> -->
    <v-btn
      color="secondary"
      @click="save_location"
      :disabled="!location || !touched"
      >Save</v-btn>
  </v-card-text>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    store: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      location: null,
      uses_shopify_labels: false,
      touched: false,
      label_size: null,
    }
  },
  computed: {
    ...mapState({
      company: (state) => state.warehouse.company,
      label_formats: state => state.app.config.label_formats.shopify
    }),
    can_use_shopify_labels() {return false
      // this.store?.shop_country_code === 'US' && this.location?.country_code === 'US'
    }
  },
  methods: {
    save_location(){
      console.log('save', this.location.id);
      if(this.uses_shopify_labels) {
        this.$store.dispatch('app/submit_log', {message: `STORE ${this.store.name}, ID ${this.store.id}, ${this.store.base_url} REQUESTED SHOPIFY LABELS ON ${new Date().toISOString()}`})
        const carriers = this.company.carriers
        const new_carrier = {
          id: 'shopify',
          type: 'shopify',
          default_label_type: this.label_size
        }
        if(carriers.some(c => c.id === 'shopify')) carriers[carriers.findIndex(c => c.id === 'shopify')] = { ...new_carrier } 
        else carriers.push({ ...new_carrier })
        this.$store.commit('warehouse/SET_COMPANY', { carriers })
        this.$store.dispatch('warehouse/save_company', ['carriers'])
      } else if(!this.uses_shopify_labels && !this.company.data_sources.filter(ds => ds.id !== this.store.id).some(ds => ds.uses_shopify_labels) && this.company.carriers.some(c => c.type === 'shopify')){
        console.log('truing remove')
        this.$store.commit('warehouse/SET_COMPANY', { carriers: this.company.carriers.filter(c => c.type !== 'shopify') })
        this.$store.dispatch('warehouse/save_company', ['carriers'])
      }
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        location_id: this.location.id,
        location_country_code: this.location.country_code,
        uses_shopify_labels: this.uses_shopify_labels,
      })
      this.touched = false
    },
  },
  async mounted(){
    await this.$store.dispatch('warehouse/get_fulfillment_locations', this.store.id)
    this.location = this.store.locations.find(i => i.id === this.store.location_id) || null
    this.uses_shopify_labels = this.store.uses_shopify_labels
    this.label_size = this.company.carriers.find(i => i.id === 'shopify') && this.company.carriers.find(i => i.id === 'shopify').default_label_type
    console.log('lz', this.label_size)
  }
}
</script>